<template lang="pug">
v-row.main-container.no-gutters
    v-col.col-12
        span.px-5.text-14.pt-2.pb-4(v-if="lastSyncDate") #[translate Última actualización:]
            span.pl-2 {{ lastSyncDate }}    
    center-google-trend-vue(v-if="data && typeNode==='center'" :loading="isPending")(:data="data")
    v-col.mt-0.px-4.col-12.pt-4#googleTrendTable(v-else)
        v-card.elevation-1.rounded-lg
            div.d-flex.py-4.px-6.items-center.justify-space-between
                div.d-flex.items-center
                    span.font-weight-medium.pr-8.label #[translate Ranking de restaurantes]
                    div.filters
                        div.filter.ml-2.mr-4
                            input.filterBySearch(type="text" v-model="search" :placeholder="$gettext('Search')")
                            button.filterBySearchButton.clean(@click="cleanBySearch" v-if="$route.query.search || search") 
                                img(:src="img.clean")
                            button.filterBySearchButton(@click="filterBySearch") 
                                img(:src="img.search")                
                span.downloadExcel(@click="handleDownloadExcel")
                    img(:src="imgCommons.download" width="10")
            google-trend-table(:loading="isPending" :items="data" @setOrder="handleOrderChange")

</template>
<script>
import CenterGoogleTrendVue from '@/components/network/CenterGoogleTrend.vue'
import commonMixing from '@/mixins/common';
import NtkService from '@/services/network';
import GoogleTrendTable from '@/components/network/GoogleTrendTable.vue';
import * as types from '@/store/mutation-types';

export default {
    mixins: [commonMixing],
    components: { CenterGoogleTrendVue, GoogleTrendTable },
    data() {
        return {
            data: [],
            isPending: false,
            img: {
                sort: require('@/assets/img/mini_arrow_shack_disable.svg'),
                search: require('@/assets/img/buscar.svg'),
                clean: require('@/assets/img/borrar.svg')
            },
            nextScrollUrl: null,
            nextCall: false,
            rankingData: [],
            ordering: ''
        }
    },
    async created() {
        this.getData()
    },
    mounted() {
        this.scroll()
    },
    computed: {
        search: {
            get() {
                return this.$store.getters.getLocalGoogleSearch
            },
            set(val) {
                this.$store.commit(types.LOCAL_GOOGLE_SEARCH, val)
            }
        },
        lastSyncDate() {
            return this.$moment(this.typeNode !== 'center' ? this.data?.[0]?.source_date : this.data?.source_date).format("DD-MM-YYYY hh:mm a")
        }
    },
    beforeDestroy() {
        window.onscroll = null;
    },
    methods: {
        handleDownloadExcel() {
            this.downloadExcel({ search: this.search, ordering: this.ordering })
        },
        async getData(ordering) {
            this.isPending = true;
            try {
                const { data } = this.nextScrollUrl ? await NtkService.getNextGoogleTrendData(this.nextScrollUrl) : await NtkService.getGoogleTrendData({ search: this.search, ordering })
                this.data = (data?.results) ? [...this.data, ...data?.results] : data
                this.nextScrollUrl = data?.next
                this.isPending = false
                this.nextCall = false
            } catch (error) {
                this.isPending = false
            }
        },
        filterBySearch() {
            this.data = []
            this.nextScrollUrl = null
            this.updateUrl()
            this.getData()
        },
        handleOrderChange(ordering) {
            this.ordering = ordering
            this.data = []
            this.getData(ordering)
        },
        cleanBySearch() {
            this.data = []
            this.$store.commit(types.LOCAL_GOOGLE_SEARCH, null)
            if (this.$route.query.search) {
                this.nextScrollUrl = null
                this.updateUrl()
                this.getData()
            }
        },
        scroll() {
            window.onscroll = () => {
                var body = document.body,
                    html = document.documentElement;
                var height = Math.max(body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight);
                height = parseInt(height, 10) - 1

                let bottomOfWindow = false
                if (document.documentElement.scrollTop + window.innerHeight > height - 5 && document.documentElement.scrollTop + window.innerHeight < height + 5) {
                    bottomOfWindow = true
                }
                if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
                    this.nextCall = true
                    this.getData();
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.filterBySearch {
    vertical-align: middle;
    font-size: 0.75rem;
    border: 1px solid #D7DAE2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
    background: white;
    color: #1976d2;
    height: 34px;
    padding-left: 16px;
}


.filterBySearchButton {
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #D7DAE2;
    border-left: 0;
    height: 34px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 10px;

    &.clean {
        border-right: 1px solid #D7DAE2;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    img {
        width: 14px;
        margin-top: 5px;
    }
}

.main-container {
    .label {
        position: relative;

        ::after {
            position: absolute;
            content: '';
            width: 60%;
            bottom: -5px;
            left: 0;
            border-bottom: 1px solid #979797;
        }
    }
}
</style>
    