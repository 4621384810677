<template>
    <div class=" w-full px-3">
        <SkeletonCard v-if="isLoading"></SkeletonCard>
        <div v-if="!isLoading" class="gridFull w-full mx-0">
            <div class="d-flex headTitleGrid">
                <div class="block-title titleGrid">
                    <span class="mb-0 title-card">
                        <span v-if="!title">{{ $gettext('Competence') }}</span>
                        <span v-else>{{ title }}</span>
                    </span>
                </div>
            </div>
            <slot></slot>
            <div class="rowInfo" v-if="data && data.length > 0">
                <div class="d-flex head">
                    <div class="col colName">
                        <div class="box"></div>
                    </div>
                    <div class="col colProgress">
                        <div class="box"></div>
                    </div>
                    <div class="col colNota text-center">
                        <div class="box justify-center">Nota</div>
                    </div>
                    <div class="col colNopiniones text-center">
                        <div class="box justify-center">Nº de opiniones</div>
                    </div>
                    <div class="col colAvgOpiniones text-center">
                        <div class="box justify-center" v-if="isGenericEntity">{{ $gettext('Media nº op. por negocio.') }}
                        </div>
                        <div class="box justify-center" v-else>{{ $gettext('Media nº op. por rest.') }}</div>
                    </div>
                    <div class="col colSentimiento text-center">
                        <div class="box justify-center">Sentimiento</div>
                    </div>
                    <div class="col colPromotoresDetractores">
                        <div class="box justify-center items-center">
                            <div class="detractores text-right">
                                <span class="mr-2">{{ $gettext('Detractors') }}</span>
                                <img :src="img.detractores" />
                            </div>
                            <div class="promotores">

                                <img :src="img.promotores" />
                                <span class="ml-2">{{ $gettext('Promoters') }}</span>
                            </div>

                        </div>
                    </div>
                    <div class="col colGPS text-center">
                        <div class="box justify-center">GPS</div>
                    </div>
                    <div class="col colShow">
                        <div class="box"></div>
                    </div>
                </div>
            </div>
            <div class="rowInfo" v-for="(item, index) in data" :key="index">

                <div class="d-flex body items-center"
                    :class="{ 'open': item.showCompetence && item.items.length > 1, 'border': !item.showCompetence }">
                    <div class="col colName">
                        <div class="box">
                            <strong v-if="index === 0 && !title && renderMediaInfo">{{ item.items[0].name }}</strong>
                            <span v-else>{{ item.items[0].name }}</span>
                        </div>
                    </div>
                    <div class="col colProgress">
                        <div class="box">
                            <ProgresBarByNote :note="item.items[0].review_rating_global" :height="12"></ProgresBarByNote>
                        </div>
                    </div>
                    <div class="col colNota text-center">
                        <div class="box justify-center">{{ item.items[0].review_rating_global | formatNumber(1, 1) }}</div>
                    </div>
                    <div class="col colNopiniones text-center">
                        <div class="box justify-center">{{ item.items[0].review_count | formatNumber(0, 0) }}</div>
                    </div>
                    <div class="col colAvgOpiniones text-center">
                        <div class="box justify-center">{{ item.items[0].avg_review_count | formatNumber(0, 0) }}</div>
                    </div>
                    <div class="col colSentimiento text-center">
                        <div class="box">
                            <ChartBarThreeColors :slim="true" :lineGuides="false" :tooltip="true" :height="12"
                                :showPercent="true" :percentGreen="item.items[0].review_percent_positive || 0"
                                :percentOrange="item.items[0].review_percent_neutral || 0"
                                :percentRed="item.items[0].review_percent_negative || 0" :render-details="true">
                            </ChartBarThreeColors>
                        </div>
                    </div>
                    <div class="col colPromotoresDetractores">
                        <div class="box">
                            <PromotoresDetractores :positive="item.items[0].gps_percent_positive"
                                :negative="item.items[0].gps_percent_negative" :height="12"></PromotoresDetractores>
                        </div>
                    </div>
                    <div class="col colGPS text-center">
                        <div class="box justify-center">{{ item.items[0].gps | formatNumber(1, 1) }} %</div>
                    </div>
                    <div class="col colShow">
                        <div class="box">
                            <button @click="item.showCompetence = !item.showCompetence"
                                v-if="item.items.length > 1 && renderUIArrow">
                                <svg :class="{ 'rotate-180': item.showCompetence }" xmlns="http://www.w3.org/2000/svg"
                                    width="17.414" height="9.414" viewBox="0 0 17.414 9.414">
                                    <g id="Grupo_11617" data-name="Grupo 11617"
                                        transform="translate(1840.707 464.707) rotate(180)">
                                        <line id="Línea_1457" data-name="Línea 1457" x1="8" y2="8"
                                            transform="translate(1832 456)" fill="none" stroke="#707070"
                                            stroke-linecap="round" stroke-width="1" />
                                        <line id="Línea_1458" data-name="Línea 1458" x2="8" y2="8"
                                            transform="translate(1824 456)" fill="none" stroke="#707070"
                                            stroke-linecap="round" stroke-width="1" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="item.items[0].sector && index === 0 && renderMediaInfo">
                    <div class="d-flex body items-center rowcompetence media my-2 py-2">
                        <div class="colName">
                            <div class="box media pl-4">
                                <span>
                                    {{ $gettext("Media de la competencia") }}
                                </span>
                            </div>
                        </div>
                        <div class="col colProgress">
                            <div class="box media">
                                <ProgresBarByNote :note="item.items[0].sector.review_rating_global" :height="8">
                                </ProgresBarByNote>
                            </div>
                        </div>
                        <div class="col colNota text-center">
                            <div class="box media justify-center">{{ item.items[0].sector.review_rating_global |
                                formatNumber(1,
                                    1) }}</div>
                        </div>
                        <div class="col colNopiniones text-center">
                            <div class="box media justify-center">{{ item.items[0].sector.review_count | formatNumber(0, 0)
                            }}
                            </div>
                        </div>
                        <div class="col colAvgOpiniones text-center">
                            <div class="box media justify-center">{{ item.items[0].sector.avg_review_count | formatNumber(0,
                                0) }}
                            </div>
                        </div>
                        <div class="col colSentimiento text-center">
                            <div class="box media">
                                <ChartBarThreeColors :slim="true" :lineGuides="false" :tooltip="true" :height="8"
                                    :showPercent="true" :percentGreen="item.items[0].sector.review_percent_positive || 0"
                                    :percentOrange="item.items[0].sector.review_percent_neutral || 0"
                                    :percentRed="item.items[0].sector.review_percent_negative || 0" :render-details="true">
                                </ChartBarThreeColors>

                            </div>
                        </div>
                        <div class="col colPromotoresDetractores">
                            <div class="box media">
                                <PromotoresDetractores :positive="item.items[0].sector.gps_percent_positive"
                                    :negative="item.items[0].sector.gps_percent_negative" :height="8">
                                </PromotoresDetractores>
                            </div>
                        </div>
                        <div class="col colGPS text-center">
                            <div class="box media justify-center">{{ item.items[0].sector.gps | formatNumber(1, 1) }} %
                            </div>
                        </div>
                        <div class="col colShow">
                            <div class="box media"></div>
                        </div>
                    </div>

                </div>
                <div v-if="item.showCompetence && item.items.length > 1">
                    <div class="d-flex body items-center rowcompetence"
                        v-for="(itemc, indexc) in item.items.filter((e, index) => index != 0)" :key="indexc">
                        <div class="col colName">
                            <div class="box">
                                <div>
                                    {{ itemc.name }}
                                    <p class="mb-0" v-if="isGenericEntity">
                                        {{ itemc.n_centers }} {{ $gettext('negocios') }}
                                    </p>
                                    <p class="mb-0" v-else>{{ itemc.n_centers }} {{ $gettext('locales') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col colProgress">
                            <div class="box">
                                <ProgresBarByNote :note="itemc.review_rating_global" :height="8"></ProgresBarByNote>
                            </div>
                        </div>
                        <div class="col colNota text-center">
                            <div class="box justify-center">{{ itemc.review_rating_global | formatNumber(1, 1) }}</div>
                        </div>
                        <div class="col colNopiniones text-center">
                            <div class="box justify-center">{{ itemc.review_count | formatNumber(0, 0) }}</div>
                        </div>
                        <div class="col colAvgOpiniones text-center">
                            <div class="box justify-center">{{ itemc.avg_review_count | formatNumber(0, 0) }}</div>
                        </div>
                        <div class="col colSentimiento text-center">
                            <div class="box">
                                <ChartBarThreeColors :slim="true" :lineGuides="false" :tooltip="true" :height="8"
                                    :showPercent="true" :percentGreen="itemc.review_percent_positive || 0"
                                    :percentOrange="itemc.review_percent_neutral || 0"
                                    :percentRed="itemc.review_percent_negative || 0" :render-details="true">
                                </ChartBarThreeColors>

                            </div>
                        </div>
                        <div class="col colPromotoresDetractores">
                            <div class="box">
                                <PromotoresDetractores :positive="itemc.gps_percent_positive"
                                    :negative="itemc.gps_percent_negative" :height="8"></PromotoresDetractores>
                            </div>
                        </div>
                        <div class="col colGPS text-center">
                            <div class="box justify-center">{{ itemc.gps | formatNumber(1, 1) }} %</div>
                        </div>
                        <div class="col colShow">
                            <div class="box"></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import mixinCommon from '@/mixins/common'
import brandMixing from '@/mixins/brand'
import ProgresBarByNote from '@/components/common/ProgressbarByNote.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'
import PromotoresDetractores from '@/components/common/PromotoresDetractores.vue'
import SkeletonCard from '@/components/common/skeletonCard.vue'

export default {
    mixins: [mixinCommon, brandMixing],
    props: ['data', 'title', 'renderMediaInfo', "renderArrow"],
    components: { ProgresBarByNote, ChartBarThreeColors, PromotoresDetractores, SkeletonCard },
    data() {
        return {
            img: {
                detractores: require('@/assets/img/detractoresPeople.svg'),
                promotores: require('@/assets/img/promotoresPeople.svg')
            },
        }
    },
    computed: {
        renderUIArrow() {
            return this.renderArrow === undefined ? true : this.renderArrow
        }
    }
}
</script>

<style lang="scss" scoped>
.rowInfo {
    .head {
        font-size: 0.688rem;

        .colPromotoresDetractores {
            .promotores {
                border-left: 1px solid black;
                padding-left: 6px;
                margin-left: 6px;
            }
        }
    }

    .head,
    .body {
        .col {
            align-self: stretch;
            align-items: center;
            display: flex;
            flex-basis: auto;
            padding: 0;

            .box {
                border-bottom: 5px solid white;

                &.media {
                    border-bottom: none;
                }

                width: 100%;
                display: flex;
                align-self: stretch;
                align-items: center;
                padding: 0 5%;
            }
        }
    }

    .body {
        background-color: #F7F7F7;
        font-size: 0.813rem;

        .col {
            .box {
                padding: 10px 5%;
            }
        }

        &.rowcompetence {
            background-color: white;

            &:nth-child(even) {
                background-color: #FAFAFA;
            }

            &.media {
                background-color: #dbd5d5;
                font-size: 10px;
            }

            .col {
                .box {
                    padding: 0 5%;
                }

                &.colName {
                    border-left: 17px solid white;
                }
            }
        }

        &.open {
            background-color: #CBEEF9;
        }
    }

    .colName {
        width: 12%;
    }

    .colProgress {
        width: 15%;
    }

    .colNota {
        width: 4%;
    }

    .colNopiniones {
        border-left: 1px solid #707070;
        width: 11%;
    }

    .colAvgOpiniones {
        border-right: 1px solid #707070;
        width: 11%;
    }

    .colSentimiento {
        border-right: 1px solid #707070;
        width: 19%;
    }

    .colPromotoresDetractores {
        width: 23%;
    }

    .colGPS {
        width: 5%;
    }

    .colShow {
        width: 3%;
    }
}
</style>